<template>
  <div style="background: #fff">
    <headers :key="itemKey" />
    <div class="head">
      <img src="@/assets/images/new.png" />
      <div class="headTit">新闻中心</div>
      <div class="searchBox">
        <search @search="searchFun" :type="2" />
      </div>

      <div class="headBomBox">
        <div class="headBomBoxItem" @click="actTabChange(1)">
          <div class="tabTit" :style="{ color: actTab == 1 ? '#ffffff' : '' }">
            新闻资讯
          </div>
          <div v-if="actTab == 1" class="tabLine"></div>
        </div>
        <div class="headBomBoxItem" @click="actTabChange(2)">
          <div class="tabTit" :style="{ color: actTab == 2 ? '#ffffff' : '' }">
            产品公告
          </div>
          <div v-if="actTab == 2" class="tabLine"></div>
        </div>
      </div>
    </div>

    <div class="breadcrumb" :key='itemKey'>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item to="/">首页</el-breadcrumb-item>
        <el-breadcrumb-item >{{actTab==1?'新闻资讯':'产品公告'}}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="content">
      <div
        class="card"
        v-for="(item, index) in list"
        :key="index"
        @click="toNewsDetails(item)"
      >
        <div class="cardImg">
          <img :src="item.image" />
        </div>
        <div class="cardImgRBox" v-if="actTab == 1">
          <div class="cardTit textOverflowOne">
            {{ item.title }}
          </div>
          <div class="cardTime">发布时间：{{ item.createTime }}</div>
          <div class="cardTextArea textOverflowTwo">
            {{ item.intro }}
          </div>
        </div>

        <div class="cardImgRBox" v-if="actTab == 2">
          <div class="card2Tit textOverflowTwo">
            {{ item.title }}
          </div>
          <div class="card2BomBox">
            <div class="card2Btn">查看详情</div>
            发布时间：{{ item.createTime }}
          </div>
        </div>
      </div>
    </div>

    <div class="page" v-if="list.length">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="pages.total"
        :current-page="pages.page"
        :page-size="pages.size"
        @current-change="currentChange"
      >
      </el-pagination>
    </div>

    <None v-else />
    <footers />
  </div>
</template>

<script>
import headers from "@/components/header.vue";
import footers from "@/components/footer.vue";
import search from "@/components/search.vue";

import { newsInfoList, newsNoticeList } from "@/api/lx";
export default {
  name: "news",
  components: {
    headers,
    footers,
    search,
  },
  data() {
    return {
      searchValue: "",
      list: [],
      itemKey: Math.random(),
      actTab: 1,
      pages: {
        page: 1,
        total: 0,
        size: 10,
      },
    };
  },
  beforeRouteUpdate(to, from, next) {
    this.actTab = to.query.id;
    this.getList();
    this.itemKey = Math.random();
    next();
  },
  created() {
    this.actTab = this.$route.query.id;
 
    this.getList();
  },
  mounted() {},
  methods: {
    toNewsDetails(item) {
      this.$router.push({
        path: "/news/details",
        query: { id: item.id,actTab:this.actTab},
      });
    },
    actTabChange(val) {
      this.actTab = val;
      if (this.actTab == 2&&!localStorage.getItem("token")) {
      this.$router.replace({
          path: "/login",
          replace: true,
        });
       return
    }
      this.pages.page = 1;
      this.getList();
    },

    currentChange(val) {
      this.pages.page = val;
      this.getList();
    },
    searchFun(val) {
      console.log(';1231')
      this.searchValue = val;
      this.pages.page = 1;
      this.getList();
    },
    getList() {
      if (this.actTab == 1) {
        newsInfoList({
          page: this.pages.page,
          size: this.pages.size,
          title: this.searchValue,
        }).then((res) => {
          this.list = res.data.records;
          this.pages.total = res.data.total;
        });
      } else {
        newsNoticeList({
          page: this.pages.page,
          size: this.pages.size,
          title: this.searchValue,
        }).then((res) => {
          this.list = res.data.records;
          this.pages.total = res.data.total;
        });
      }
    },

  },
};
</script>

<style lang="less" scoped>
.head {
  width: 100%;
  height: 300px;
  position: relative;
  overflow: hidden;
  .headTit {
    width: 100%;
    text-align: center;
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 42px;
    color: #ffffff;
    position: relative;
    z-index: 1;
    margin-top: 70px;
  }
  .searchBox {
    margin-top: 34px;
  }
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
.headBomBox {
  position: absolute;
  left: 0;
  bottom: 2px;
  width: 100%;
  height: 58px;
  display: flex;
  align-items: center;
  justify-content: center;
  .headBomBoxItem {
    width: 80px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin: 0 110px;
    font-weight: 400;
    font-size: 20px;
    cursor: pointer;
    color: rgba(255, 255, 255, 0.7);
    .tabTit{
      width: 86px;
      text-align: center;
    }
  }
  .tabLine {
    width: 72px;
    height: 3px;
    background: #ffffff;
  }
}
.content {
  width: 100%;
  box-sizing: border-box;
  padding-left: 6px;
  box-sizing: border-box;
  padding-top: 30px;
  min-height: 400px;
  display: flex;
  flex-wrap: wrap;
  .card {
    width: 894px;
    height: 186px;
    background: #f9f9f9;
    border: 1px solid #e6edf0;
    margin-left: 38px;
    margin-bottom: 38px;
    display: flex;
    box-sizing: border-box;
    .cardImg {
      width: 330px;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .cardImgRBox {
      width: 542px;
      height: 100%;
      padding: 20px;
      padding-right: 11px;
      padding-top: 32px;
      box-sizing: border-box;
      .cardTit {
        width: 100%;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 24px;
        color: #00161a;
      }
      .cardTime {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #829199;
        margin-top: 10px;
      }
      .cardTextArea {
        width: 100%;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 16px;
        color: #525f66;
        margin-top: 12px;
      }
      .card2Tit {
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 24px;
        line-height: 33px;
        color: #00161a;
        height: 66px;
      }
      .card2BomBox {
        display: flex;
        align-items: center;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #525f66;
        margin-top: 13px;
        .card2Btn {
          width: 104px;
          height: 39px;
          text-align: center;
          line-height: 39px;
          background: #00d8c3;
          font-family: PingFangSC, PingFang SC;
          font-weight: 600;
          font-size: 14px;
          color: #ffffff;
          margin-right: 20px;
        }
      }
    }
  }
}

.page {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 65px;
}
</style>